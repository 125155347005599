<template>
  <a
    class="mobile:mt-10 tablet:mt-20 mx-auto flex mobile:w-full tablet:min-w-[250px] tablet:w-full tablet:max-w-[400px] tablet:w-auto items-center justify-center gap-3 px-[32px] py-[18px] bg-white rounded-[64px] text-black mobile:text-[16px] tablet:text-[20px] font-semibold"
    :href="props.link ? props.link : ''"
    :target="props.blank ? '_blank' : ''"
  >
    {{ props.text }}
    <slot></slot>
    <svg
      v-if="props.hasTg"
      width="26"
      height="26"
      viewBox="0 0 280 281"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140 23.4893C75.5999 23.4893 23.3333 75.7559 23.3333 140.156C23.3333 204.556 75.5999 256.823 140 256.823C204.4 256.823 256.667 204.556 256.667 140.156C256.667 75.7559 204.4 23.4893 140 23.4893ZM194.133 102.823C192.383 121.256 184.8 166.056 180.95 186.706C179.317 195.456 176.05 198.373 173.017 198.723C166.25 199.306 161.117 194.289 154.583 189.973C144.317 183.206 138.483 179.006 128.567 172.473C117.017 164.889 124.483 160.689 131.133 153.923C132.883 152.173 162.75 124.989 163.333 122.539C163.414 122.168 163.403 121.783 163.302 121.417C163.2 121.051 163.011 120.715 162.75 120.439C162.05 119.856 161.117 120.089 160.3 120.206C159.25 120.439 142.917 131.289 111.067 152.756C106.4 155.906 102.2 157.539 98.4666 157.423C94.2666 157.306 86.3332 155.089 80.3832 153.106C73.0332 150.773 67.3166 149.489 67.7832 145.406C68.0166 143.306 70.9333 141.206 76.4166 138.989C110.483 124.173 133.117 114.373 144.433 109.706C176.867 96.1726 183.517 93.8392 187.95 93.8392C188.883 93.8392 191.1 94.0726 192.5 95.2393C193.667 96.1726 194.017 97.4559 194.133 98.3893C194.017 99.0893 194.25 101.189 194.133 102.823Z"
        fill="black"
      />
    </svg>

    <svg
      v-if="props.hasIcon"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.125 11.375L23.25 3.25M23.25 3.25H17.8333M23.25 3.25V8.66667M23.25 15.1667V20.5833C23.25 21.158 23.0217 21.7091 22.6154 22.1154C22.2091 22.5217 21.658 22.75 21.0833 22.75H5.91667C5.34203 22.75 4.79093 22.5217 4.3846 22.1154C3.97827 21.7091 3.75 21.158 3.75 20.5833V5.41667C3.75 4.84203 3.97827 4.29093 4.3846 3.8846C4.79093 3.47827 5.34203 3.25 5.91667 3.25H11.3333"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="props.hasArrow"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.585786 0.585786C1.36683 -0.195262 2.63316 -0.195262 3.41421 0.585786L10 7.17157L16.5858 0.585786C17.3668 -0.195263 18.6332 -0.195263 19.4142 0.585786C20.1953 1.36683 20.1953 2.63316 19.4142 3.41421L11.4142 11.4142C10.6332 12.1953 9.36683 12.1953 8.58578 11.4142L0.585786 3.41421C-0.195263 2.63316 -0.195262 1.36683 0.585786 0.585786Z"
        fill="black"
      />
    </svg>
  </a>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  link: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
  hasIcon: {
    type: Boolean,
    default: true,
  },
  hasArrow: {
    type: Boolean,
    default: false,
  },
  hasTg: {
    type: Boolean,
    default: false,
  },
  blank: {
    type: Boolean,
    default: true,
  },
});
</script>

<style lang="scss"></style>
