import axios from "axios";

const apiUrl = "https://back.mdk.is/api/v1";
const api = axios.create({
  baseURL: apiUrl,
});

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export function getPriceList() {
  return api.get(`${apiUrl}/get-rates`);
}
