<template>
  <Modal
    v-show="showModal"
    @close-modal="showModal = false"
    title="Showreel"
    description=""
  >
    <div class="flex text-black">
      <iframe
        src="https://www.youtube.com/embed/z4ckdaBK40o?si=CKmQ6g6pQTKqp3oL"
        width="920"
        height="320"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </Modal>
  <div id="case" class="mt-[120px] block flex-col">
    <aos-vue animation="fade" duration="700" easing="ease-in-out">
      <div
        class="mobile:text-[32px] text-center text-white tablet:text-[48px] font-bold"
      >
        Яркие кейсы
      </div>
      <div
        class="grid desktop:grid-rows-2 gap-8 mobile:grid-cols-1 desktop:grid-cols-3 grid-[32px] w-full mt-10"
      >
        <div
          class="row-span-2 desktop:col-span-2 border border-white rounded-[32px] mobile:p-[20px] tablet:p-[32px]"
        >
          <a href="" class="flex mobile:justify-center tablet:justify-between">
            <div
              class="flex mobile:flex-col mobile:justify-center tablet:flex-row items-center gap-5"
            >
              <img
                class="desktop:w-[100px]"
                src="@/assets/client/bk.svg"
                alt=""
              />
              <div
                class="mobile:text-center mobile:text-[26px] tablet:text-left tablet:text-[32px] font-bold"
              >
                Burger King SMM
              </div>
            </div>
          </a>
          <div class="flex flex-col gap-5 mt-15">
            <div class="pointed pl-4 mobile:text-lg tablet:text-2xl">
              Разработка новой концертной стратегии
            </div>
            <div class="pointed pl-4 mobile:text-lg tablet:text-2xl">
              Проведение спецпроектов с блогерами
            </div>
            <div class="pointed pl-4 mobile:text-lg tablet:text-2xl">
              Запуск стикерпака и чат-бота
            </div>
          </div>
          <div class="uppercase mobile:text-xl tablet:text-2xl font-bold mt-12">
            Блогер для бренда: BIG RUSSIAN BOSS
          </div>
          <div class="mt-5 mobile:text-lg tablet:text-xl">
            Успешный запуск продукта «Биг кинг за 99 рублей» с большим Русским
            босом. 5,5 млн просмотров на оригинальном видео, больше 113 тысячи
            лайков
          </div>
          <div class="flex mobile:flex-col tablet:flex-row mt-10 gap-5">
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=vnsUpM7R5Ag"
              class="mobile:w-full tablet:w-5/12 h-38"
            >
              <img
                class="h-inherit object-cover rounded-[12px]"
                src="@/assets/case/brb.png"
                alt=""
              />
            </a>
            <img
              class="mobile:w-full tablet:w-7/12 h-38"
              src="@/assets/case/bk-mem.png"
              alt=""
            />
          </div>
        </div>
        <div
          class="border border-white rounded-[32px] mobile:py-[28px] mobile:px-[20px] tablet:p-[32px]"
        >
          <a href="" class="flex mobile:justify-center tablet:justify-between">
            <div class="flex items-center gap-5">
              <img src="../../assets/client/garnier.svg" alt="" />
            </div>
          </a>
          <div class="flex flex-col gap-5 mt-10">
            <div class="pointed pl-4 mobile:text-lg tablet:text-2xl">
              Разработка древа чат-бота
            </div>
            <div class="pointed pl-4 mobile:text-lg tablet:text-2xl">
              Интеграция промокодов в сообщение от бота
            </div>
            <div class="pointed pl-4 mobile:text-lg tablet:text-2xl">
              Обновление текстового древа чат-бота
            </div>
          </div>
        </div>
        <div
          class="border border-white rounded-[32px] mobile:py-[28px] mobile:px-[20px] tablet:p-[32px]"
        >
          <a href="" class="flex mobile:justify-center tablet:justify-between">
            <div class="flex items-center gap-5">
              <img src="../../assets/client/yota.svg" alt="" />
            </div>
            <!--            <img-->
            <!--              src="@/assets/icons/share.svg"-->
            <!--              class="mobile:hidden tablet:block"-->
            <!--              alt=""-->
            <!--            />-->
          </a>
          <div class="flex flex-col gap-5 mt-10">
            <div class="pointed pl-4 mobile:text-lg tablet:text-2xl">
              Рекламная кампания в соцсетях
            </div>
          </div>
          <img class="mt-6" src="@/assets/case/yota.png" alt="" />
        </div>
      </div>
      <div class="flex flex-row mobile:flex-col tablet:flex-row">
        <BigButton
          link="/cases.pdf"
          :has-arrow="true"
          :has-icon="false"
          class="w-full"
          >Скачать презентацию</BigButton
        >
        <BigButton @click="openVideo($event)">Все кейсы</BigButton>
      </div>
    </aos-vue>
  </div>
</template>

<style lang="scss" scoped>
.pointed {
  position: relative;

  &::before {
    display: flex;
    width: 10px;
    left: 0;
    height: 10px;
    position: absolute;
    top: 12px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5C1 6.06087 1.42143 7.07828 2.17157 7.82843C2.92172 8.57857 3.93913 9 5 9C6.06087 9 7.07828 8.57857 7.82843 7.82843C8.57857 7.07828 9 6.06087 9 5C9 3.93913 8.57857 2.92172 7.82843 2.17157C7.07828 1.42143 6.06087 1 5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 767px) {
  .pointed {
    &::before {
      top: 9px;
    }
  }
}
</style>
<script setup>
import BigButton from "@/components/BigButton.vue";
import Modal from "@/components/ModalPrice.vue";
import { ref } from "vue";

const showModal = ref(false);
const openVideo = (e) => {
  e.preventDefault();
  showModal.value = true;
};
</script>
