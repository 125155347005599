<template>
  <div id="main" class="block flex-col pt-10 relative">
    <div
      ref="nav"
      class="flex items-center mobile:bg-black mobile:justify-between desktop:justify-center w-full mobile:fixed mobile:w-mobile desktop:relative mobile:z-30 mobile:top-0 mobile:py-5"
    >
      <router-link to="/">
        <img
          src="@/assets/icons/mdk.svg"
          class="mobile:w-[80px] desktop:w-[180px]"
          alt=""
        />
      </router-link>
      <div
        @click="isOpenMenu = !isOpenMenu"
        class="mobile:visible desktop:hidden flex items-center justify-center transform rotate-45 bg-white w-[55px] h-[55px] rounded-xl"
      >
        <img
          v-if="isOpenMenu"
          class="transform -rotate-45"
          src="@/assets/icons/burger-close.svg"
          alt=""
        />
        <img
          v-else
          class="transform -rotate-45"
          src="@/assets/icons/burger-open.svg"
          alt=""
        />
      </div>
    </div>
    <aos-vue animation="fade" duration="700" delay="100" easing="ease-in-out">
      <div
        class="mobile:text-[24px] mobile:mt-28 text-white mt-10 tablet:text-[40px] jost font-semibold"
      >
        MDK CREATIVE AGENCY
      </div>
    </aos-vue>

    <div
      :class="{
        'mobile:hidden': !isOpenMenu,
        'mobile:visible': isOpenMenu,
        fixed: isFixed,
      }"
      class="flex desktop:block nav mobile:fixed desktop:relative mobile:top-0 mobile:mt-0 desktop:mt-[80px] mobile:flex-col desktop:flex-row mobile:bg-black desktop:bg-none mobile:w-full desktop:w-auto mobile:h-screen desktop:h-auto mobile:justify-center mobile:gap-8 desktop:gap-4 mobile:z-20"
    >
      <a
        v-for="(item, i) in menuItems"
        :key="i"
        @click="scrollToSection($event, i)"
        :href="`${item.section}`"
        >{{ item.name }}</a
      >
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { menuItems } from "../../collection/menuItems";

const activeIndex = ref(0),
  isFixed = ref(false),
  isOpenMenu = ref(false),
  scrollToSection = (event, i) => {
    event.preventDefault();
    const targetId = event.target.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 120,
        behavior: "smooth",
      });

      activeIndex.value = i;
      if (isOpenMenu.value) isOpenMenu.value = !isOpenMenu.value;
    } else {
      location.href = "/#" + targetId;
    }
  };
</script>

<style lang="scss" scoped>
.nav {
  a {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 32px;

    &.active {
      background: #fff;
      color: #000;
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav {
    a {
      font-size: 20px;
      font-weight: 600;
      padding: 8px 16px;
      border-radius: 32px;
      text-transform: uppercase;
      text-align: center;

      &.active {
        background: none;
        color: #fff;
      }
    }
  }
}
</style>
