<template>
  <div id="price" class="mobile:mt-[120px] desktop:mt-[180px] block flex-col">
    <div
      class="mobile:text-[32px] text-white tablet:text-[48px] font-bold text-center"
    >
      Важное <br class="mobile:block desktop:hidden" />
      о размещении
    </div>

    <div class="flex flex-col max-w-[900px] w-full mt-20 gap-[32px]">
      <div
        class="mobile:p-[20px] desktop:p-[32px] bg-white rounded-[16px]"
        v-for="(item, i) in faq_list"
        :key="i"
        @click="selectFaq(i)"
      >
        <div class="flex flex-row items-center justify-between cursor-pointer">
          <div
            class="text-black mobile:text-[16px] desktop:text-[20px] font-semibold"
          >
            {{ item.q }}
          </div>
          <img
            v-show="activeFaq !== i"
            src="@/assets/icons/faq-plus.svg"
            alt=""
            class="mobile:w-[32px] mobile:h-[32px] desktop:w-[45px] mobile:h-[45px]"
          />
          <img
            v-show="activeFaq === i"
            src="@/assets/icons/faq-minus.svg"
            alt=""
          />
        </div>
        <div
          v-show="activeFaq === i"
          class="text-black mt-4 pr-[50px] text-[#6F6C90]"
          v-html="item.a"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { faq_list } from "@/collection/faq";

const activeFaq = ref(null);

const selectFaq = (item) => {
  activeFaq.value = item;
};
</script>
