export const menuItems = [
  {
    section: "#main",
    name: "Главная",
  },
  {
    section: "#possibility",
    name: "Возможности",
  },
  {
    section: "#case",
    name: "Яркие кейсы",
  },
  {
    section: "#client",
    name: "Довольные клиенты",
  },
  {
    section: "#platform",
    name: "Площадки для промо",
  },
  {
    section: "#price",
    name: "Купить рекламу",
  },
  {
    section: "#employee",
    name: "Команда",
  },
];
