<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
body {
  background: #000;
  font-family: "Inter", sans-serif;
  color: #fff;
  scrollbar-width: thin; /* "auto" или "thin"  */
  scrollbar-color: white white;
  .block {
    max-width: 1320px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: black;
  }
  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 3px solid white;
  }

  .jost {
    font-family: "Jost", sans-serif;
  }

  .section-title {
    font-size: 64px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  body {
    .block {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
</style>
