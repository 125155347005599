<template>
  <div
    id="client"
    class="mobile:mt-[80px] mt-desktop:mt-[120px] block flex-col"
  >
    <div
      class="mobile:text-[32px] text-white tablet:text-[48px] font-bold text-center"
    >
      Довольные клиенты
    </div>
    <div class="flex gap-4 mt-10">
      <div
        @click="prevSlide"
        class="flex cursor-pointer items-center justify-center h-13 w-13 bg-white rounded-full"
      >
        <img class="w-6 h-6" src="@/assets/icons/chevron-left.svg" alt="" />
      </div>
      <div
        @click="nextSlide"
        class="flex cursor-pointer items-center justify-center h-13 w-13 bg-white rounded-full"
      >
        <img class="w-6 h-6" src="@/assets/icons/chevron-right.svg" alt="" />
      </div>
    </div>
    <swiper
      :slides-per-view="4"
      :space-between="50"
      class="max-w-[100%] client-slider mobile:mt-10 mobile:h-[140px] tablet:h-[335px]"
      @swiper="onSwiper"
      loop
      :breakpoints="{
        1240: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      }"
      ref="client"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(slide, i) in client_list"
        :key="i"
        class="flex justify-center items-center w-full"
      >
        <img :src="slide.src" class="mobile:w-1/2" alt="" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { client_list } from "@/collection/client";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const clientSlider = ref(null),
  onSwiper = (swiper) => {
    clientSlider.value = swiper;
  },
  onSlideChange = () => {
    console.log("slide change");
  },
  prevSlide = () => {
    clientSlider.value.slidePrev();
  },
  nextSlide = () => {
    clientSlider.value.slideNext();
  };
</script>

<style lang="scss">
.client-slider {
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
}
</style>
