<template>
  <div
    id="possibility"
    class="mobile:mt-[80px] mt-desktop:mt-[120px] block flex-col"
  >
    <aos-vue animation="fade" duration="700" easing="ease-in-out">
      <div
        class="mobile:text-[32px] text-center text-white tablet:text-[48px] font-bold"
      >
        Возможности
      </div>

      <div
        class="grid mobile:grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 desktop:grid-rows-3 w-full justify-center items-center mt-10"
      >
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/1.svg" />
          <div class="text-[20px] text-center font-semibold">
            Мемы в тренде <br />и бренд-ценностях
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/2.svg" />
          <div class="text-[20px] text-center font-semibold">
            Контентная <br />и бренд стратегия
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/3.svg" />
          <div class="text-[20px] text-center font-semibold">
            Вирусные истории <br />и спецпроекты
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/4.svg" />
          <div class="text-[20px] text-center font-semibold">
            Создание уникальных <br />
            иллюстраций
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/5.svg" />
          <div class="text-[20px] text-center font-semibold">
            Креативный копирайт <br />
            постов и сообщений
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/6.svg" />
          <div class="text-[20px] text-center font-semibold">
            Разработка концепта <br />
            и древа чат-ботов
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/7.svg" />
          <div class="text-[20px] text-center font-semibold">
            Креативная поддержка <br />
            (общение в чатах)
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/8.svg" />
          <div class="text-[20px] text-center font-semibold">
            Креативный консалтинг <br />
            по идеям и трендам
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-[32px] py-[30px]"
        >
          <img class="max-w-[120px]" src="@/assets/possibility/9.svg" />
          <div class="text-[20px] text-center font-semibold">
            Промо проектов, продуктов и кинопремьер
          </div>
        </div>
      </div>
    </aos-vue>
  </div>
</template>

<script setup></script>
