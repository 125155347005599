export const work_list = [
  {
    title: "Выбор формата и рекламы",
    text: "Необходимо ознакомиться с нашими правилами размещения и прайсом на услуги. Затем связаться с нашим менеджером и сообщить о предпочтительном формате работы. ",
  },
  {
    title: "Оплата услуги",
    text: "При помощи рекламного менеджера и после полного согласования всех нюансов рекламной кампании, осуществляете оплату. Обратите внимание, что способы для физических и юридических лиц отличаются",
  },
  {
    title: "Размещение рекламы",
    text: "После подтверждения менеджером оплаты, происходит процесс публикации рекламы, ознакомьтесь с пунктом маркировка рекламы. Рекламный менеджер находится на связи с вами до и после публикации если у вас возникнут дополнительные вопросы.",
  },
];
