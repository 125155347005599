export const platform_list = [
  {
    platform: require("@/assets/icons/vk.svg"),
    account_amount: 26,
    subs: "30m",
    mau: "17m",
  },
  {
    platform: require("@/assets/icons/tg.svg"),
    account_amount: 12,
    subs: "1.5m",
    mau: "300k",
    isPublic: true,
  },
  {
    platform: require("@/assets/icons/tw.svg"),
    account_amount: 2,
    subs: "1.3m",
    mau: "2m",
  },
];
