export const client_list = [
  {
    src: require("@/assets/client/kfc.svg"),
  },
  {
    src: require("@/assets/client/sber.svg"),
  },
  {
    src: require("@/assets/client/bk.svg"),
  },
  {
    src: require("@/assets/client/sprite.svg"),
  },
  {
    src: require("@/assets/client/ebay.svg"),
  },
  {
    src: require("@/assets/client/game-insight.svg"),
  },
  {
    src: require("@/assets/client/centpart.svg"),
  },
  {
    src: require("@/assets/client/xbox.svg"),
  },
  {
    src: require("@/assets/client/durex.svg"),
  },
  {
    src: require("@/assets/client/axe.svg"),
  },
];
