export const faq_list = [
  {
    q: "Согласование рекламной кампании и публикация",
    a: "Для новых клиентов публикация рекламы возможна только по предоплате. <br><br> Рекламные материалы, а также дата, время и формат размещения согласовываются заранее в индивидуальном порядке с менеджером по рекламе и оплачиваются лишь после утверждения всех условий. Для постоянных крупных клиентов действуют особые условия оплаты",
  },
  {
    q: "Маркировка рекламы",
    a: "Мы соблюдаем закон по маркировке и следим, чтобы все наши размещения соответствовали законодательству РФ. Если у вас нет возможности самостоятельно промаркировать ОРД рекламные материалы, то мы можем предложить осуществление этой услуги для вас.",
  },
  {
    q: "Ценовая политика",
    a: "Наше агентство стремится к прозрачности и гибкости в ценообразовании, предлагая индивидуальные решения под каждого клиента. Мы учитываем особенности бюджета и целей рекламной кампании. Для постоянных клиентов у нас действует система лояльности и гибкие схемы оплаты. <br><br> Наша цель - обеспечить максимальную эффективность и результативность рекламы для каждого клиента при оптимальном соотношении цены и качества услуг.",
  },
  {
    q: "Запрещенный контент",
    a: "Мы не публикуем материалы, нарушающие законы Российской Федерации. Менеджер имеет право отказать вам в публикации, пояснив причины отказа.",
  },
  {
    q: "Как происходит оплата",
    a: "Мы принимаем оплату: <br> - от физических лиц — через онлайн-кассу <br><br> - от юридических лиц — на счет ООО, с заключением договора или счет-договора. Документооборот ведется через ЭДО.\n",
  },
  {
    q: "Возврат средств",
    a: "Возврат средств возможен только до момента публикации рекламных материалов. Срок возврата – 14 дней.",
  },
];
